import { bonusClient, BonusDeliveryTour, BonusManualData } from "api/bonus";
import { CreateOrUpdateGroupOfBonusCommandDto } from "api/bonus/command/createOrUpdateGroupOfBonus";
import { bonusGroupClient } from "api/bonusGroup";
import { CreateBonusGroupIncidentCommandDTO } from "api/bonusGroup/command/createBonusGroupIncident";
import { ResolveBonusGroupIncidentCommandDTO } from "api/bonusGroup/command/resolveBonusGroupIncident";
import {
  BonusFormDetailsState,
  BonusFormStatsRowState,
  BonusFormStatsState,
} from "app/components/Bonus/BonusForm/model";
import { Dispatch } from "redux";
import { router } from "store/router";
import { actions } from "./bonus.actions";

const createBonus = (form: BonusFormDetailsState) => {
  return async (dispatch: Dispatch) => {
    const command = toCreateOrUpdateCommandDto(form);
    const event = await bonusClient.createOrUpdateGroupOfBonus(command);
    // const fetched = await bonusGroupClient.query.fetchBonusGroup(
    //   createEvent.payload.bonusId
    // );
    // dispatch(actions.createBonus(fetched));
    dispatch(router.goto.bonus.bonusList(0, 10));
  };
};

const updateBonus = (form: BonusFormDetailsState) => {
  return async (dispatch: Dispatch) => {
    const command = toCreateOrUpdateCommandDto(form);
    const event = await bonusClient.createOrUpdateGroupOfBonus(command);
    // const updatedBonus = await bonusGroupClient.query.fetchBonusGroup(
    //   event.payload.bonusId
    // );
    // dispatch(actions.updatedBonus(updatedBonus));
    // dispatch(router.goto.bonus.bonusView(event.payload.bonusId));
  };
};

const toCreateOrUpdateCommandDto = (
  form: BonusFormDetailsState
): CreateOrUpdateGroupOfBonusCommandDto => ({
  carrierId: form.carrierId,
  operatorId: form.operatorId,
  territory: form.territory.code,
  period: form.period,
  bonuses: form.bonuses.map(toBonusManualData),
});

const toBonusManualData = (bonus: BonusFormStatsState): BonusManualData => ({
  areaCode: bonus.areaCode,
  campaignId: bonus.campaignId,
  deliveryTours: bonus.manualData.map(toBonusDeliveryTour),
});

const toBonusDeliveryTour = (
  tour: BonusFormStatsRowState
): BonusDeliveryTour => ({
  tourId: tour.id,
  deliveryCount: tour.activeDeliveryCount,
  packCount: tour.packCount,
  duration: tour.duration,
  manuallyDeclaredDate: tour.manuallyDeclaredDate ?? Date.now(),
});

const publishBonus = (bonusId: string) => {
  return async (dispatch: Dispatch) => {
    const event = await bonusGroupClient.publishBonusGroup(bonusId);
    const updatedBonus = await bonusGroupClient.query.fetchBonusGroup(
      event.payload.bonusGroupId
    );
    dispatch(actions.updatedBonus(updatedBonus));
  };
};

const validateBonus = (bonusId: string) => {
  return async (dispatch: Dispatch) => {
    const event = await bonusGroupClient.validateBonusGroup(bonusId);
    const updatedBonus = await bonusGroupClient.query.fetchBonusGroup(
      event.payload.bonusGroupId
    );
    dispatch(actions.updatedBonus(updatedBonus));
  };
};

const rejectBonus = (bonusId: string, reasons: string) => {
  return async (dispatch: Dispatch) => {
    const event = await bonusGroupClient.rejectBonusGroup(bonusId, reasons);
    const updatedBonus = await bonusGroupClient.query.fetchBonusGroup(
      event.payload.bonusGroupId
    );
    dispatch(actions.updatedBonus(updatedBonus));
  };
};

const createBonusIncident = (bonusGroupId: string, description: string) => {
  return async (dispatch: Dispatch) => {
    const dto: CreateBonusGroupIncidentCommandDTO = {
      bonusGroupId: bonusGroupId,
      description: description,
      type: {
        value: "MANUAL",
      },
      properties: {},
    };
    const event = await bonusGroupClient.createBonusGroupIncident(dto);
    const updatedBonus = await bonusGroupClient.query.fetchBonusGroup(
      event.payload.bonusGroupId
    );
    dispatch(actions.updatedBonus(updatedBonus));
  };
};

const resolveBonusIncident = (bonusGroupId: string, incidentId: string) => {
  return async (dispatch: Dispatch) => {
    const dto: ResolveBonusGroupIncidentCommandDTO = {
      bonusGroupId: bonusGroupId,
      incidentId: incidentId,
    };
    const event = await bonusGroupClient.resolveBonusGroupIncident(dto);
    const updatedBonus = await bonusGroupClient.query.fetchBonusGroup(
      event.payload.bonusGroupId
    );
    dispatch(actions.updatedBonus(updatedBonus));
  };
};

const resetBonus = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.resetBonus());
  };
};

const deleteBonus = (bonusId: string) => {
  return async (dispatch: Dispatch) => {
    await bonusGroupClient.deleteBonusGroup(bonusId).then(
      () => dispatch(router.goto.bonus.bonusList(0, 10))
    )
  };
};

export const commands = {
  createBonus: createBonus,
  updateBonus: updateBonus,
  createBonusIncident: createBonusIncident,
  resolveBonusIncident: resolveBonusIncident,
  resetBonus: resetBonus,
  publishBonus: publishBonus,
  rejectBonus: rejectBonus,
  validateBonus: validateBonus,
  deleteBonus: deleteBonus,
};
